import { mapActions } from "vuex";

import VueLoadingButton from "vue-loading-button";

export default {
  data() {
    return {
      version: JSON.stringify(require("../../../package.json").version),
      description: JSON.stringify(require("../../../package.json").description),
      isLoading: false,
      isStyled: false,
      errors: [],
      form: {
       // email: null,
       Cedula:null,
        password: null
      }

    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      tema: 'tema/tema',
    }),
    submit() {
      this.errors = [];
     let valor = this.form.Cedula
     //Compruebo si es un valor numérico
      if (isNaN(valor)) {
           //entonces (no es numero) devuelvo el valor cadena vacia
           this.errors.push('Cedula solo perimte numeros');
       }
       else{
           //En caso contrario (Si era un número) devuelvo el valor
           if (this.form.Cedula && this.form.password) {
            this.isLoading = true;
            this.signIn(this.form).then(() => {
             
              this.$router.replace({
                name: 'home'
              }),
              this.tema();
              //let a = JSON.stringify(localStorage.getItem("usuario"))
             
            }).catch(() => {
              this.isLoading = false
              console.log('fail');
              this.errors.push('Usuario o contraseña incorrectos.');
            })
          }
      }
     
      

      if (!this.form.Cedula) {
        this.errors.push('Campo Cedula requerido.');
      }
      if (!this.form.password) {
        this.errors.push('Password requerdio.');
      }

    }
  },
  components: {
    VueLoadingButton
  }
}